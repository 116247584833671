@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: 'Poppins', sans-serif;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}